import router from '@/router';
import vuex from '@/store';
import { NavigationGuard } from 'vue-router/types/router';
import { Marketplace, Store } from '@/entities';
import { getAllAccounts } from '@/router/routes/guards/get-all-accounts';
import { PagesName } from '@/router/routes/pages-enum';
import Vue from 'vue';

export const CatalogCreateOfferGuard: NavigationGuard = async (to, from, next) => {
  try {
    const store = vuex.getters['store/store'] as Store;

    const accounts = await getAllAccounts();

    const hasSomeAccountDisconnected = accounts?.some((account) => !account.isAuthenticated());

    const hasShopeeMultiAccounts = Vue.$toggle.isEnabled('shopee-multi-accounts');
    if (hasShopeeMultiAccounts && hasSomeAccountDisconnected) {
      return router.push({ name: PagesName.ACCOUNTS, query: {} });
    }

    if (accounts && !accounts[0].isAuthenticated()) {
      return router.push({ name: PagesName.LEGACY_ACCOUNTS, params: { error: 'creating-offer' } });
    }

    const activeMarketplace = vuex.getters['marketplace/activeMarketplace'] as Marketplace;
    if (!activeMarketplace.isOfferCreationEnabled()) {
      return router.push({
        name: PagesName.PUBLISHED_OFFERS,
        params: { marketplace: activeMarketplace.name },
      });
    }

    // Se a loja registrada na vuex difere da loja atual,
    // limpa os registros e retorna para tela de seleção de produtos
    const storeId = vuex.getters['offers/creating/storeId'];
    if (store.id !== storeId) {
      await vuex.dispatch('offers/creating/setItems', []);
      return router.push({ name: 'catalog-add-offer-products' });
    }

    const items = vuex.getters['offers/creating/items'] as Array<number>;
    if (items.length > 0 && to.name === 'catalog-add-offer') {
      return router.push({ name: 'catalog-add-offer-review' });
    }

    if (items.length === 0 && to.name !== 'catalog-add-offer-products') {
      return router.push({ name: 'catalog-add-offer-products' });
    }

    next();
  } catch (e) {
    return next();
  }
};
