import Vue from 'vue';

export const shouldLoadMultiAccountsByMarketplace = (marketplaceName: string | null) => {
  if (
    marketplaceName &&
    marketplaceName.toLowerCase() === 'shopee' &&
    !Vue.$toggle.isEnabled('new-flow-shopee')
  ) {
    return Vue.$toggle.isEnabled('shopee-multi-accounts');
  }

  return Vue.$toggle.isEnabled('multi-contas-listagem');
};
