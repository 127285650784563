export type SelectedItemStorageKey = 'create-offer_selected-items';
export type StoreIdStorageKey = 'create-offer_store-id';

export const storageKeys = {
  selectedItems: 'create-offer_selected-items',
  storeIdStorageKey: 'create-offer_store-id',
};

export type Item = {
  product_id: number;
  offer_id?: number;
  account_id?: number;
};

export interface CreateOfferState {
  storeId: number | null;
  items: Array<Item>;
}
