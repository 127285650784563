import { PagesName } from '@/router/routes/pages-enum';
import NProgress from 'nprogress';
import Vue from 'vue';
import { NavigationGuard } from 'vue-router';

/**
 * Função que valida se o marketplace precisa de toggle para ativar o fluxo novo, ou se é
 * um marketplace que tem apenas o novo fluxo de acordo com o nome do marketplace
 * registrado na lista marketplacesWithBothFlows.
 *
 *  @param {string} marketplaceName - nome do marketplace em letra minúscula
 */
export const shouldLoadNewFlowByMarketplaceName = (marketplaceName?: string): boolean => {
  if (!marketplaceName) return false;

  const marketplaceLower = marketplaceName.toLowerCase();
  const marketplacesWithBothFlows = ['shopee'];

  const hasBothFlows = marketplacesWithBothFlows.includes(marketplaceLower);

  if (!hasBothFlows) return true;

  return Vue.$toggle.isEnabled(`new-flow-${marketplaceLower}`);
};

/**
 * Valida as páginas que precisam de redirect para outras rotas com o componente adequado
 * no new-flow
 *
 * @param routeName - Nome da rota que será validada
 * @returns - Retorna o nome da rota que será redirecionada ou uma string vazia
 */
const redirectPagesNewFlow = (routeName: string): string => {
  if (routeName !== PagesName.PUBLISHED_OFFERS && routeName === PagesName.LEGACY_PUBLISHED_OFFERS) {
    return PagesName.PUBLISHED_OFFERS;
  }

  if (routeName !== 'new-pending-orders' && routeName === 'marketplaces-pending-orders') {
    return 'new-pending-orders';
  }

  return '';
};

/**
 * Guard para as rotas do novo fluxo do hub
 * @returns carrega a rota no fluxo ou se não tiver habilitado o fluxo novo, redireciona para a rota de anúncios
 */
export const toggleNewFlow: NavigationGuard = async (to, from, next) => {
  const routesNewFlow = [
    PagesName.OFFERS_IN_PROCESSING,
    PagesName.OFFERS_AWAITING_CORRECTION,
    PagesName.PUBLISHED_OFFERS,
    PagesName.OFFERS_ADD,
    PagesName.CATEGORY_RELATIONSHIP,
    PagesName.PENDING_SALES,
  ];

  const marketplaceName = to.params.marketplace;

  const isToLoadTheNewFlow = shouldLoadNewFlowByMarketplaceName(marketplaceName);

  const isRouteOfNewFlow = routesNewFlow.includes(to.name as PagesName);

  if (isRouteOfNewFlow && isToLoadTheNewFlow) {
    return next();
  }

  if (isRouteOfNewFlow) {
    const redirectToOldFlowOfOffers = PagesName.LEGACY_PUBLISHED_OFFERS;

    return next({ name: redirectToOldFlowOfOffers, params: to.params });
  }

  const redirectToName = redirectPagesNewFlow(to.name as string);
  if (isToLoadTheNewFlow && redirectToName && redirectToName !== to.name) {
    /** Cancelamento de loading necessário ao usar rotas com mesmo path, futuramente avaliar remoção dessa linha */
    NProgress.done();

    return next({
      name: redirectToName,
      params: Object.assign(to.params, { noTransition: true }),
      query: to.query,
    });
  }

  return next();
};
